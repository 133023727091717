import styled from 'styled-components'

import {BASE_CONF} from 'components/common/CPTheme'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  background-color: ${props => props.theme.colors.secondary1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TitleWrapper = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  width: 65%;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  color: ${props => props.theme.colors.secondary10};
  font-size: 12px;
  line-height: 16px;

  @media only screen and ${BASE_CONF.screens.md} {
    font-size: ${props => (props.shouldUseSmallFont ? '18px' : '34px')};
    line-height: ${props => (props.shouldUseSmallFont ? '25px' : '46px')};
  }
`

export const WatPhraKaewWrapper = styled.div`
  position: absolute;
  top: 13%;
  left: 0;
  width: 17%;
`
export const ArtWrapper = styled.div`
  position: absolute;
  bottom: -1%;
  left: 19%;
  width: 17%;
`
export const MasksWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 18%;
  width: 8%;
`
export const PisaTower = styled.div`
  position: absolute;
  top: 8%;
  left: 77%;
  width: 17%;
`
export const LocationPinWrapper = styled.div`
  position: absolute;
  top: 47%;
  right: 0;
  width: 8%;
`
export const RuinsWrapper = styled.div`
  position: absolute;
  bottom: 1%;
  right: 0;
  width: 17%;
`
