import React from 'react'

import Art from './components/Art.svg'
import LocationPin from './components/LocationPin.svg'
import Masks from './components/Masks.svg'
import PisaTower from './components/PisaTower.svg'
import Ruins from './components/Ruins.svg'
import WatPhraKaew from './components/WatPhraKaew.svg'

import * as S from './styled'

const DefaultAttractionImage = ({title, shouldUseSmallFont = false}) => (
  <S.Container>
    <S.WatPhraKaewWrapper>
      <WatPhraKaew />
    </S.WatPhraKaewWrapper>
    <S.ArtWrapper>
      <Art />
    </S.ArtWrapper>
    <S.MasksWrapper>
      <Masks />
    </S.MasksWrapper>
    <S.PisaTower>
      <PisaTower />
    </S.PisaTower>
    <S.LocationPinWrapper>
      <LocationPin />
    </S.LocationPinWrapper>
    <S.RuinsWrapper>
      <Ruins />
    </S.RuinsWrapper>
    <S.TitleWrapper shouldUseSmallFont={shouldUseSmallFont}>{title}</S.TitleWrapper>
  </S.Container>
)

export default DefaultAttractionImage
