import styled from 'styled-components'
import {BASE_CONF} from 'components/common/CPTheme'

export const primaryColor = '#253044'

export const PaginationWrapper = styled.div`
  margin: 64px auto 0 auto;
  display: flex;
  width: fit-content;

  > * {
    margin: 0 4px;
  }

  > *:first-child {
    margin-right: 20px;
  }

  > *:last-child {
    margin-left: 20px;
  }
`

export const PageItem = styled.div`
  width: 38px;
  height: 38px;
  background: ${primaryColor};
  border-radius: 8px;
  display: ${props => (props.isArrow ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.disabled ? '0.16' : props.current ? 1 : '0.32')};
  cursor: ${props => (props.disabled ? 'not-allowed' : props.cursor)};

  @media only screen and ${BASE_CONF.screens.sm} {
    display: flex;
  }
`

export const PageLabel = styled.p`
  font-style: normal;
  font-weight: normal;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: white;
  margin: 0;
`

export const PageArrow = styled.img`
  display: block;
`
