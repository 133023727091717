import styled from 'styled-components'
import {BASE_CONF} from 'components/common/CPTheme'

export const Wrapper = styled.div``

export const GridWrapper = styled.div`
  display: grid;
  grid-gap: 16px;

  @media screen and ${BASE_CONF.screens.md} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px 24px;
  }

  @media screen and ${BASE_CONF.screens.xl} {
    grid-template-columns: repeat(4, 1fr);
  }
`
