import React from 'react'

import Pagination from 'components/common/Pagination'

import * as S from './styled'

function CPGridWithPagination({
  className,
  items = [],
  ItemComponent = () => {},
  currentPage = 1,
  setCurrentPage = () => {},
  totalPages = 1,
  loading = false
}) {
  return (
    <S.Wrapper className={className}>
      <S.GridWrapper>
        {items.map((item, index) => (
          <ItemComponent
            loading={loading}
            key={`${index}-${item.id || item.src || item.title}`}
            {...item}
          />
        ))}
      </S.GridWrapper>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </S.Wrapper>
  )
}

export default CPGridWithPagination
