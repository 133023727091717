import React from 'react'

import AttractionDefaultImage from '../../common/AttractionPreview/components/DefaultAttractionImage'

import * as S from './styled'

function CPCardItem(item) {
  const imgSrc = item.src || item.image

  return (
    <S.CardItemWrapper className={item.className}>
      <S.CardItemImageWrapper loading={item.loading} className="grid-image-wrapper">
        {item.loading ? null : imgSrc ? (
          <S.CardItemImage
            doesImageExist={!!imgSrc}
            src={imgSrc ? imgSrc : '/static/images/logo/cp_logo_gray.svg'}
          />
        ) : (
          <AttractionDefaultImage shouldUseSmallFont={true} title={item.title} />
        )}
      </S.CardItemImageWrapper>
      <S.TextWrapper>
        <S.CardItemTitle loading={item.loading}>{item.title}</S.CardItemTitle>
        <S.CardItemSubtitle loading={item.loading}>{item.subtitle}</S.CardItemSubtitle>
      </S.TextWrapper>
    </S.CardItemWrapper>
  )
}

export default CPCardItem
