import styled, {css} from 'styled-components'

import {BASE_CONF} from 'components/common/CPTheme'

const {screens} = BASE_CONF

const Skeleton = css`
  background-color: ${props => props.theme.colors.gray};

  &:after {
    content: '';
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-name: loader-animate;
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff',
      endColorstr='#00ffffff',
      GradientType=1);
  }

  @keyframes loader-animate {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }
`

export const CardItemWrapper = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  display: flex;
  flex-direction: row;

  @media screen and ${screens.md} {
    flex-direction: column;
  }
`
export const TextWrapper = styled.div`
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and ${screens.md} {
    padding-left: 0;
    padding-top: 16px;
  }
`

export const CardItemImageWrapper = styled.div`
  flex-shrink: 0;
  width: 96px;
  height: 96px;
  border-radius: 16px;
  background-color: #f9f9f9;

  @media screen and ${screens.md} {
    width: 100%;
    height: 166px;
  }

  @media screen and ${screens.lg} {
    width: 100%;
    height: 200px;
  }

  position: relative;
  overflow: hidden;
  ${props => (props.loading ? Skeleton : '')};
`

export const CardItemImage = styled.img`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const CardItemTitle = styled.h3`
  color: ${props => (props.loading ? props.theme.colors.gray : props.theme.colors.dark2)};
  width: ${props => (props.loading ? '22%' : 'auto')};
  height: ${props => (props.loading ? '16px' : 'auto')};
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 2px;
  margin-top: 0;
  font-style: normal;
  font-weight: 600;

  @media screen and ${screens.md} {
    font-size: 18px;
    line-height: 25px;
  }

  position: relative;
  overflow: hidden;
  ${props => (props.loading ? Skeleton : '')};
`

export const CardItemSubtitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${props => (props.loading ? props.theme.colors.gray : props.theme.colors.secondary4)};
  width: ${props => (props.loading ? '72%' : 'auto')};
  height: ${props => (props.loading ? '16px' : 'auto')};
  position: relative;
  overflow: hidden;
  ${props => (props.loading ? Skeleton : '')};
`
