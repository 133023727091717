import React from 'react'

import {withTranslation} from '../../index'

import * as S from './styled'

export const PAGE_SKIP_NUMBER = 2

function Pagination({currentPage, setCurrentPage, totalPages}) {
  const pagesToRender = [1, totalPages]
  currentPage = parseInt(currentPage, 10)

  if (currentPage !== 1 && currentPage !== totalPages) {
    pagesToRender.push(currentPage)
  }

  if (currentPage - 1 > 1) {
    pagesToRender.push(currentPage - 1)
  }

  if (currentPage + 1 < totalPages) {
    pagesToRender.push(currentPage + 1)
  }

  pagesToRender.sort((a, b) => a - b)

  if (pagesToRender.length > PAGE_SKIP_NUMBER) {
    if (pagesToRender[1] - pagesToRender[0] > 1) {
      pagesToRender.splice(1, 0, '...')
    }
    if (pagesToRender[pagesToRender.length - 1] - pagesToRender[pagesToRender.length - 2] > 1) {
      pagesToRender.splice(pagesToRender.length - 1, 0, '...')
    }
  }

  const switchPage = value => {
    if (currentPage + value >= 1 && currentPage + value <= totalPages) {
      setCurrentPage(currentPage + value)
    }
  }

  return (
    totalPages > 1 && (
      <S.PaginationWrapper className="cp-pagination-wrapper">
        <S.PageItem
          isArrow={true}
          onClick={() => switchPage(-1)}
          disabled={currentPage === 1}
          cursor={'pointer'}
        >
          <S.PageArrow src={'/static/images/institutions-page-previous.svg'} />
        </S.PageItem>

        {pagesToRender.map((pageNumber, index) => (
          <S.PageItem
            key={index}
            onClick={pageNumber === '...' ? null : () => setCurrentPage(pageNumber)}
            cursor={pageNumber === '...' ? 'default' : 'pointer'}
            current={pageNumber === currentPage}
          >
            <S.PageLabel>{pageNumber}</S.PageLabel>
          </S.PageItem>
        ))}

        <S.PageItem
          isArrow={true}
          onClick={() => switchPage(1)}
          disabled={currentPage === totalPages}
          cursor={'pointer'}
        >
          <S.PageArrow src={'/static/images/institutions-page-next.svg'} />
        </S.PageItem>
      </S.PaginationWrapper>
    )
  )
}

export default withTranslation(Pagination)
